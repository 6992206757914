module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Benso GmbH","short_name":"Benso","start_url":"/","background_color":"#00a58d","theme_color":"#00a58d","display":"standalone","icon":"src/assets/img/website-icon.png","icons":[{"src":"favicons/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"favicons/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"favicons/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"favicons/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"favicons/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"favicons/icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"favicons/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"favicons/icon-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1419ca30b8513b3d4648d044a29bd024"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-157386081-3","cookieName":"gatsby-gdpr-google-analytics","anonymize":true},"googleTagManager":{"trackingId":"","cookieName":"gatsby-gdpr-google-tagmanager","dataLayerName":"dataLayer"},"facebookPixel":{"pixelId":"","cookieName":"gatsby-gdpr-facebook-pixel"},"environments":["production"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
